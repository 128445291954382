<template>
  <div class="wrapper-filters-nigeria">
    <input type="text" class="wrapper-filters-nigeria__input" placeholder="Credit limit is 80 000 ₦">
    <button class="wrapper-filters-nigeria__button"><img src="../assets/nigeria_page_svg/filters-nigeria.svg" alt="" class="wrapper-filters-nigeria__button--img"><span class="wrapper-input-nigeria__button--text">Filters</span></button>
  </div>
</template>

<script>
export default {
  name: "NigeriaFilters",
}
</script>