<template>
  <nigeria-navbar></nigeria-navbar>
  <router-view></router-view>
  <NigeriaFooter></NigeriaFooter>
</template>

<script>

import NigeriaNavbar from "@/components/Nigeria-navbar.vue";
import NigeriaFooter from "@/components/Nigeria-footer.vue";

export default {
  components: {NigeriaFooter, NigeriaNavbar}
}
</script>
