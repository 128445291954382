<template>
  <div class="footer-nigeria">
    <div class="footer-nigeria__wrapper">
      <div class="footer-nigeria__wrapper--title">
        <img src="@/assets/item_svg/footer_img/footer_logo_nig.svg" alt="" class="footer-nigeria__wrapper--title__img">
        <a href="" class="footer-nigeria__wrapper--title__doc mb-20">PRIVACY POLICY</a>
        <a href="" class="footer-nigeria__wrapper--title__doc">USER AGREEMENT</a>
      </div>
      <div class="footer-nigeria__wrapper--content">
        <h3 class="footer-nigeria__wrapper--content__title">Kreditka.com — a financial service created to help users find and compare loan offers from various banks and financial institutions. We strive to be a trusted and convenient resource for making informed financial decisions.</h3>
        <p class="footer-nigeria__wrapper--content__disclaimer">Kreditka.com LLC uses cookies to improve user convenience, provide more opportunities and ensure the proper level of performance of the site and services. Cookies are small files that contain information about preferences and previous visits to a website. If you do not want to use cookies, you can change your browser settings. Read more about the terms of use here.</p>
        <div class="footer-nigeria__wrapper--content__disclaimer">
          <p class="mb-35">The content of the site does not constitute a recommendation or offer and is for informational and reference purposes only.</p>
          <p>Trademark certificate No. 558734 dated August 20, 2020.</p>
        </div>
        <h2 class="footer-nigeria__wrapper--content__contacts">Address: 117638, Moscow, Odesskaya street, 2, floor 19 8 995 756-49-60 Kreditka@gmail.com</h2>
      </div>
      <div class="footer-nigeria__wrapper--copyright">
        <p>© 2020 – 2023, Kreditka.com LLC. All rights reserved. When using materials, a hyperlink to Kreditka.ru is required.</p>
        <div class="footer-nigeria__wrapper--copyright__links">
          <a href="" ><img src="@/assets/item_svg/footer_img/instagram_img.svg" alt="" class="footer-nigeria__wrapper--copyright__links__img"></a>
          <a href="" ><img src="@/assets/item_svg/footer_img/telegram_img.svg" alt="" class="footer-nigeria__wrapper--copyright__links__img"></a>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-nigeria-mobil">
    <div class="footer-nigeria-mobil__title">
      <div class="footer-nigeria-mobil__title--description">
        <div class="footer-nigeria-mobil__title--description__logo">
          <img src="@/assets/item_svg/footer_img/footer_logo_nig.svg" alt="" class="footer-nigeria-mobil__title--description__logo--img">
        </div>
        <h3 class="footer-nigeria-mobil__title--description__text">Kreditka.com — a financial service created to help users find and compare loan offers from various banks and financial institutions. We strive to be a trusted and convenient resource for making informed financial decisions.</h3>
      </div>
      <div class="footer-nigeria-mobil__title--button">
        <a href="" class="footer-nigeria-mobil__title--button__doc">PRIVACY POLICY</a>
        <a href="" class="footer-nigeria-mobil__title--button__doc">USER AGREEMENT</a>
        <div class="footer-nigeria-mobil__title--button__links">
          <a href="" ><img src="@/assets/item_svg/footer_img/instagram_img.svg" alt="" class="footer-nigeria-mobil__title--button__links--img mr-27"></a>
          <a href="" ><img src="@/assets/item_svg/footer_img/telegram_img.svg" alt="" class="footer-nigeria-mobil__title--button__links--img"></a>
        </div>
      </div>
    </div>
    <div class="footer-nigeria-mobil__wrapper">
      <p class="footer-nigeria-mobil__wrapper--disclaimer">Kreditka.com LLC uses cookies to improve user convenience, provide more opportunities and ensure the proper level of performance of the site and services. Cookies are small files that contain information about preferences and previous visits to a website. If you do not want to use cookies, you can change your browser settings. Read more about the terms of use here.</p>
      <div class="footer-nigeria-mobil__wrapper--disclaimer">
        <p class="mb-35">The content of the site does not constitute a recommendation or offer and is for informational and reference purposes only.</p>
        <p>Trademark certificate No. 558734 dated August 20, 2020.</p>
      </div>
      <h2 class="footer-nigeria-mobil__wrapper--contacts">Address: 117638, Moscow, Odesskaya street, 2, floor 19 8 995 756-49-60 Kreditka@gmail.com</h2>
      <p class="footer-nigeria-mobil__wrapper--copyright">© 2020 – 2023, Kreditka.com LLC. All rights reserved. When using materials, a hyperlink to Kreditka.ru is required.</p>
    </div>
  </div>

</template>
<script setup lang="ts">
</script>