<template>
  <div class="bg-nig">
    <div class="container">
      <div class="title-nig">
        <div class="title-nig--wrapper">
          <div>
            <h1 class="title-nig--wrapper__title">Selecting and<br> comparing of <br><span class="title-nig--wrapper__title--yellow">CREDIT PRODUCTS</span></h1>
            <p class="title-nig--wrapper__text">Profitable propositions of banks in 2024</p>
          </div>

          <div class="title-nig--wrapper__img">
            <img class="" src="@/assets/nigeria_page_svg/title_img_nig.svg" alt="">
          </div>
        </div>
      </div>
      <div class="title-mobil-nig">
        <div class="title-mobil-nig__wrapper">
          <h1 class="title-mobil-nig__wrapper--title">Selecting and comparing of </h1>
          <span class="title-mobil-nig__wrapper--yellow">CREDIT PRODUCTS</span>
          <p class="title-mobil-nig__wrapper--text">Profitable propositions of banks in 2024</p>
        </div>
        <img class="title-mobil-nig__img" src="@/assets/nigeria_page_svg/title_mobil_nig.svg" alt="">
      </div>

      <div class="input_credit-nig">
        <div class="input_credit-nig__wrapper">
          <h3 class="input_credit-nig__wrapper--text">Select a credit card options</h3>
          <div class="input_credit-nig__wrapper--filters">
            <input type="range" min="0" max="200" value="100" class="input_credit-nig__wrapper--filters__input-range" />
            <nigeria-filters class="input_credit-nig__wrapper--filters__input-text"></nigeria-filters>
          </div>
            <button-nigeria class="input_credit-nig__wrapper--button">Selected 78 cards</button-nigeria>
        </div>
      </div>
      <div class="input_credit-nig-mobil">
        <div class="input_credit-nig-mobil__wrapper">
          <nigeria-filters class="input_credit-nig-mobil__wrapper--filters"></nigeria-filters>
          <button-nigeria class="input_credit-nig-mobil__wrapper--button">Selected 78 cards</button-nigeria>
        </div>
      </div>

      <div class="container-allbanks-nig">
        <h2 class="container-allbanks-nig__title">All banks of <span class="container-allbanks-nig__title--color">Manila</span></h2>
        <p class="container-allbanks-nig__text">On Kreditka.com You’ll always find a bank, wich will approve your loan</p>
        <div class="container-allbanks-nig__input">
          <input-text-nigeria></input-text-nigeria>
          <input-text-nigeria class="full-none"></input-text-nigeria>
        </div>
        <div class="container-allbanks-nig__logo-banks">
          <img src="@/assets/page_svg/cinabank_img.svg" alt="">
          <img src="@/assets/page_svg/BDO_img.svg" alt="">
          <img src="@/assets/page_svg/BPI_img.svg" alt="">
          <img src="@/assets/page_svg/Metrobank_img.svg" alt="">
          <img src="@/assets/page_svg/eastwest_img.svg" alt="">
          <img src="@/assets/page_svg/security_bank_img.svg" alt="">
          <img src="@/assets/page_svg/bank_plank_img.svg" alt="">
        </div>
      </div>
      <div class="allbanks-mobil">
        <Splide :options="{ rewind: true, width: 350, type: 'loop', arrows: false, pagination: false, gap: 55, preloadPages: 3}" aria-label="" >
          <SplideSlide  v-for="banks in AllBanks" class="Slide_Element">
            <div class="bank-box">
              <img :src="banks" alt="" class="">
            </div>
          </SplideSlide>
        </Splide>
      </div>

      <div class="loans-nig">
        <h2 class="loans-nig__title">Loan with accurate conditions</h2>
        <p class="loans-nig__text">Find your best options on Kreditka.com</p>
        <div class="loans-nig__wrapper">
          <div class="loans-nig__wrapper--box">
            <h3 class="loans-nig__wrapper--box__title">We’ll find a loan for you</h3>
            <ul class="loans-nig__wrapper--box__list">
              <li class="mb-15">We’ll help you a loan for your parameteres with the best % rate, fast</li>
              <li class="mb-15">We use only relevant information about banks propositions</li>
              <li class="">All data is reserved and proceed in syphered conditions</li>
            </ul>
          </div>
          <div class="loans-nig__wrapper--box">
            <h3 class="loans-nig__wrapper--box__title">Fill your own blank</h3>
            <ul class="loans-nig__wrapper--box__list">
              <li class="list-dash mb-8">Send your application within martch 22 - june 23</li>
              <li class="list-dash">Apply for a loan before the 9th of july</li>
            </ul>
            <button-nigeria class="btn_loans">Apply</button-nigeria>
          </div>
        </div>
      </div>

      <div class="loans-mobil-nig">
        <h2 class="loans-mobil-nig__title">Loan with accurate conditions</h2>
        <p class="loans-mobil-nig__text">Find your best options on Kreditka.com</p>
        <div class="loans-mobil-nig__wrapper">
          <div class="loans-mobil-nig__wrapper--box mb-25">
            <h3 class="loans-mobil-nig__wrapper--box__title">We’ll find a loan for you</h3>
            <ul class="loans-mobil-nig__wrapper--box__list">
              <li class="list-dash">We’ll help you a loan for your parameteres with the best % rate, fast</li>
              <li class="list-dash">We use only relevant information about banks propositions</li>
              <li class="list-dash">All data is reserved and proceed in syphered conditions</li>
            </ul>
          </div>
          <div class="loans-mobil-nig__wrapper--box">
            <h3 class="loans-mobil-nig__wrapper--box__title">Fill your own blank</h3>
            <ul class="loans-mobil-nig__wrapper--box__list">
              <li class="list-dash mb-10">Send your application within martch 22 - june 23</li>
              <li class="list-dash">Apply for a loan before the 9th of july</li>
            </ul>
          </div>
          <button-nigeria class="btn_loans">Apply</button-nigeria>
        </div>
      </div>

      <div class="priv-nig">
        <h2 class="priv-nig__title">Our priviledges</h2>
        <div class="priv-nig__wrapper">
          <div class="priv-nig__wrapper--box color-priv-1">
            <div class="priv-nig__wrapper--box__content">
              <img src="../assets/nigeria_page_svg/priv-nig-1.svg" alt="" class="priv-nig__wrapper--box__content--img">
              <h3 class="priv-nig__wrapper--box__content--title w-125-nig">Wide choice</h3>
            </div>
            <div class="priv-nig__wrapper--box__content--text">
              <p>Access to wide variety of finantial products from different banks</p>
            </div>
          </div>
          <div class="priv-nig__wrapper--box color-priv-2">
            <div class="priv-nig__wrapper--box__content">
              <img src="../assets/nigeria_page_svg/priv-nig-2.svg" alt="" class="priv-nig__wrapper--box__content--img">
              <h3 class="priv-nig__wrapper--box__content--title">Apply where it’s convenient</h3>
            </div>
            <div class="priv-nig__wrapper--box__content--text">
              <p>You can choose going to an office or do everything online</p>
            </div>
          </div>
          <div class="priv-nig__wrapper--box color-priv-3">
            <div class="priv-nig__wrapper--box__content">
              <img src="../assets/nigeria_page_svg/priv-nig-3.svg" alt="" class="priv-nig__wrapper--box__content--img">
              <h3 class="priv-nig__wrapper--box__content--title w-125-nig">No hidden conditions</h3>
            </div>
            <div class="priv-nig__wrapper--box__content--text">
              <p>Neutral and objective information about accessable products</p>
            </div>
          </div>
        </div>
      </div>

      <div class="reviews-nig full-none">
        <h2 class="reviews-nig__title">Client reviews</h2>
        <p class="reviews-nig__text">Latest reviews about banking products</p>
        <div class="reviews-nig__wrapper">
          <div class="reviews-nig__wrapper--box">
            <div class="reviews-nig__wrapper--box__img">
              <img src="../assets/nigeria_page_svg/reviews-nig-1.png" alt="" class="h-w-img">
            </div>
            <h3 class="reviews-nig__wrapper--box__title">African-American Names</h3>
            <p class="reviews-nig__wrapper--box__text">In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its... In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased wiwith its...In June I applied for a credit th its...</p>
            <div class="reviews-nig__wrapper--box__star">
              <img src="@/assets/nigeria_page_svg/star_nigeria.svg" alt="" >
            </div>
          </div>
          <div class="reviews-nig__wrapper--box">
            <div class="reviews-nig__wrapper--box__img">
              <img src="../assets/nigeria_page_svg/reviews-nig-2.png" alt="" class="h-w-img">
            </div>
            <h3 class="reviews-nig__wrapper--box__title">African Names</h3>
            <p class="reviews-nig__wrapper--box__text">I have never seen such a cashback before!!! Easy to use, works with any  I have never seen such a cashback.  for a credit card from this bank and was pleased with its... In June I applied for a credit card from this bank and wa seen such a cashback before!!! Easy to use, works with any  I have never seen such a cashback.  for a credit card from this bank and was pleased with its... In June I applied for a credit card from this this bank and was pleased with its...In June I applied for a credit</p>
            <div class="reviews-nig__wrapper--box__star">
              <img src="@/assets/nigeria_page_svg/star_nigeria.svg" alt="" class="reviews-nig__wrapper--box__star">
            </div>
          </div>
          <div class="reviews-nig__wrapper--box">
            <div class="reviews-nig__wrapper--box__img">
              <img src="../assets/nigeria_page_svg/reviews-nig-3.png" alt="" class="h-w-img">
            </div>
            <h3 class="reviews-nig__wrapper--box__title">American Names</h3>
            <p class="reviews-nig__wrapper--box__text">I have always considered this bank to be reliable, serious and with a good I have never seen such a cashback...r a credit card from this bank and was pleased with its... In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with it this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...</p>
            <div class="reviews-nig__wrapper--box__star">
              <img src="@/assets/nigeria_page_svg/star_nigeria.svg" alt="">
            </div>
          </div>
        </div>
        <button-nigeria class="btn_reviews">All reviews</button-nigeria>
      </div>
      <div class="reviews-mobil-nig">
        <div class="reviews-mobil-nig__wrapper">
          <Splide :options="{ rewind: true, width: 350, type: 'loop',arrows: false, gap: 16}" aria-label="" >
            <SplideSlide class="Slide_Element">
              <div class="reviews-mobil-nig__wrapper--box">
                <div class="reviews-mobil-nig__wrapper--box__title">
                  <div class="reviews-mobil-nig__wrapper--box__title--img">
                    <img src="../assets/nigeria_page_svg/reviews-nig-1.png" alt="" class="h-w-img">
                  </div>
                  <h3 class="reviews-mobil-nig__wrapper--box__title--h3">African-American Names</h3>
                </div>
                <p class="reviews-mobil-nig__wrapper--box__text">In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its... In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased wiwith its...In June I applied for a credit th its...</p>
                <div class="reviews-mobil-nig__wrapper--box__star">
                  <img src="@/assets/nigeria_page_svg/star_nigeria.svg" alt="" >
                </div>
              </div>
            </SplideSlide>
            <SplideSlide class="Slide_Element">
              <div class="reviews-mobil-nig__wrapper--box">
                <div class="reviews-mobil-nig__wrapper--box__title">
                  <div class="reviews-mobil-nig__wrapper--box__title--img">
                    <img src="../assets/nigeria_page_svg/reviews-nig-2.png" alt="" class="h-w-img">
                  </div>
                  <h3 class="reviews-mobil-nig__wrapper--box__title--h3">African Names</h3>
                </div>
                <p class="reviews-mobil-nig__wrapper--box__text">I have never seen such a cashback before!!! Easy to use, works with any  I have never seen such a cashback.  for a credit card from this bank and was pleased with its... In June I applied for a credit card from this bank and wa seen such a cashback before!!! Easy to use, works with any  I have never seen such a cashback.  for a credit card from this bank and was pleased with its... In June I applied for a credit card from this this bank and was pleased with its...In June I applied for a credit</p>
                <div class="reviews-mobil-nig__wrapper--box__star">
                  <img src="@/assets/nigeria_page_svg/star_nigeria.svg" alt="" >
                </div>
              </div>
            </SplideSlide>
            <SplideSlide class="Slide_Element">
              <div class="reviews-mobil-nig__wrapper--box">
                <div class="reviews-mobil-nig__wrapper--box__title">
                  <div class="reviews-mobil-nig__wrapper--box__title--img">
                    <img src="../assets/nigeria_page_svg/reviews-nig-3.png" alt="" class="h-w-img">
                  </div>
                  <h3 class="reviews-mobil-nig__wrapper--box__title--h3">American Names</h3>
                </div>
                <p class="reviews-mobil-nig__wrapper--box__text">I have always considered this bank to be reliable, serious and with a good I have never seen such a cashback...r a credit card from this bank and was pleased with its... In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with it this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...</p>
                <div class="reviews-mobil-nig__wrapper--box__star">
                  <img src="@/assets/nigeria_page_svg/star_nigeria.svg" alt="" >
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import InputText from "@/components/Input_text.vue";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import NigeriaFilters from "@/components/Nigeria_filters.vue";
import ButtonNigeria from "@/components/Button-nigeria.vue";
import InputTextNigeria from "@/components/Input-text-nigeria.vue";
import NigeriaNavbar from "@/components/Nigeria-navbar.vue";
import NigeriaFooter from "@/components/Nigeria-footer.vue";

export default {
  name: 'HomeView',
  components: {
    NigeriaFooter,
    NigeriaNavbar,
    InputTextNigeria, ButtonNigeria, NigeriaFilters, InputText, Splide, SplideSlide},
  data() {
    return {
      AllBanks: [
        require('@/assets/page_svg/cinabank_img.svg'),
        require('@/assets/page_svg/BDO_img.svg'),
        require('@/assets/page_svg/BPI_img.svg'),
        require('@/assets/page_svg/Metrobank_img.svg'),
        require('@/assets/page_svg/eastwest_img.svg'),
        require('@/assets/page_svg/security_bank_img.svg'),
        require('@/assets/page_svg/bank_plank_img.svg'),

      ]
    }
  }
}
</script>