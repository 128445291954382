<template>
  <button @click="buttonClick" class="btn-nigeria">
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'ButtonNigeria',
}
</script>